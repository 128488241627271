import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout/hcp'
import BrandCTA from '../../../components/brand-cta'
import codingTable from '../../../images/coding-table-r1.webp'

const metaTags = {
  description: 'Learn about suggested billing codes for SUPPRELIN® LA (histrelin acetate) subcutaneous implant.',
  keywords: 'Homepage',
  title: 'Suggested Billing & Coding | SUPPRELIN® LA (histrelin acetate)'
}

// const externalLinksMetaTags = {
//   category: 'External Link',
//   action: 'Click',
//   label: 'Deerfield Homepage',
// }

const IndexPage = () => (
  <Layout meta={metaTags}>
    <Row>
      <Col xs={12}>
        <h1>Suggested billing and coding*</h1>
      </Col>
    </Row>
    <Row center='xs'>
      <Col xs={12}>
        <img src={codingTable} alt="Suggested Billing Coding Table" title="Suggested Billing Coding Table" />
        <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: 821, textAlign: 'left' }}>
          <p style={{ textIndent: -7, marginLeft: 7 }}>
            <strong>*Coding is part of the clinical decision. Please use codes that most accurately reflect the procedures performed. Suggestions by Endo do not guarantee reimbursement or take the place of professional coding advice.</strong>
          </p>
        </div>
      </Col>
    </Row>
    <Row between="xs">
      <Col xs={12} md={6}>
        <BrandCTA
          href={'/hcp/downloads-office-support'}
          LinkCaption="Discover important reimbursement forms for your practice - billing coding"
        >
          Discover important reimbursement forms for <nobr>your practice</nobr>

        </BrandCTA>
      </Col>
      <Col xs={12} md={6}>
        <BrandCTA
          ExternalLink
          href="https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/bb39c5e5-b03c-40fb-b52b-3110919e08ff/bb39c5e5-b03c-40fb-b52b-3110919e08ff_source__v.pdf"
          LinkCaption="Download the SUPPRELIN<sup>&reg;</sup> LA caregiver brochure: important information for patients - billing coding"
        >
          Download the SUPPRELIN<sup>&reg;</sup> LA Caregiver Brochure: important information for patients
        </BrandCTA>
      </Col>
    </Row>
  </Layout>
)

export default IndexPage;
